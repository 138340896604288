@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #3f4e4b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.site-message{
  position: relative;
  color: white;
  margin: auto;
  font-size: 30pt;
  height: 100vh;
  padding-top: 20%;
}

.header{
  background: #032222;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 270px;
}

#logo{
  margin:auto;
  margin-top: 40px;
}

#logo img{
  width:200px;
  margin-right: 60px;
}

.sub-header{
  margin-top: 20px;
}

.nav-container a{
  margin-right: 80px;
  padding: 20px;
}

.social-container{
  font-size: 16pt;
  text-align: right;
  color: white;
}

.social-icon{
  margin-right: 15px;
  padding: 10px;
}

.sub-header a{
  text-decoration: none;
  color: white;
}

.sub-header a:hover{
  color: chartreuse;
}

#cartContainer{
  display: inline-block;
  position: fixed;
  color: white;
  right: 0;
  top: 0;
  padding: 10px;
  padding-right: 20px;
  border-bottom-left-radius: 4px;
  font-size: 15pt;
}

.page{
  margin-top:260px;
}

.page .content-section.landing-banner{
  height:80vh;
  background: black;
}

#footerBlock{
  min-height: 300px;
  background: #032222;
  padding: 10px 150px 100px 150px;
  color:#8aa0a0;
}

#footerBlock #footerContent{
  margin-top: 10px;
}

/* #footerEmblem{
  width: 80px;
  height: 80px;
  background-repeat: no-repeat;
  background-image: url(./images/VWLogoWh.png);
  background-size: contain;
  opacity: 0.25;
  position: relative;
  margin: auto;
} */
